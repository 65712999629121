
<template>
  <div>
    <vue-tel-input
        class="dropPhone no-shadow"
      v-model="numero"
      :defaultCountry="tel ? tel.phone_iso_code : null"
      v-bind="vueTel"
      @input="check"
      required
    >
    </vue-tel-input>
  </div>
</template>

<script>
import "vue-phone-number-input/dist/vue-phone-number-input.css";
export default {
  data() {
    return {
      numero: null,
      vueTel: {
        preferredCountries: ["FR", "TN"],
        placeholder: "Enter your phone",
        mode: "auto",
        inputOptions: {
          showDialCode: true,
        },
        disabledFormatting: false,
        validCharactersOnly: true,
        wrapperClasses: "country-phone-input",
      },
    };
  },
  props: {
    tel: Object,
  },
  methods: {
    check(phone, event) {
      this.$emit("phoneNumber", event);
    },
  },
  mounted() {
    if (this.tel) this.numero = this.tel.phone_number;
  },
};
</script>

<style scoped>
::v-deep input {
  box-shadow: none !important;
}

.vue-tel-input[data-v-48307be2][data-v-50294cc3] {
    border-radius: 3px;
    display: flex;
    text-align: left;
    box-shadow: none !important;
    border-color: #ced4da !important;
    height: 41.1px !important;
    border-radius: 5px !important;
}
::v-deep .dropdown.open {
  z-index: 20 !important;
  box-shadow: none !important;
 
}
::v-deep ul[data-v-48307be2] {
    z-index: 1;
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;
    top: 33px;
    left: -1px;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 375px !important;
    overflow-x: clip !important;
}
.dropdown.open {
    z-index: 20 !important;
}
::v-deep  vue-tel-input country-phone-input{
  box-shadow: none !important;
}
.no-shadow .country-phone-input input {
  box-shadow: none !important;
}
.country-phone-input input {
  box-shadow: none !important;
}
.vue-tel-input[data-v-48307be2] {
    border-radius: 3px;
    display: flex;
    text-align: left;
    box-shadow: none !important;
    border-color: #ced4da !important;
}
</style>

